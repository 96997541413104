import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="terms-popup" title="Terms & Conditions">
      
      <ol>
    <li dir="ltr">
        <p dir="ltr">
            This offer entitles the end user to get cashback on their Online
            Wallet or UPI or NEFT. The customer will be responsible for their
            choice of the cashback transfer method.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Any consumer purchasing a promotional product shall be eligible to
            receive a Unique Code printed inside the pack The customer shall
            receive a cashback of an amount between Rs 20/- to Rs 100/-
            ("Cashback/Voucher Amount"), except the state of Tamil Nadu where a
            fixed cashback of Rs 20/- will be provided for every unique code.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            By redeeming the Offer provided on the same, a participant agrees to
            be bound by the terms and conditions below ("Conditions").
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            These Conditions apply to every person ("Participant/Customer")
            redeeming the offer given by Premier Sales Promotions Pvt Ltd, no
            claim shall lie for any redemption requests made after the expiry of
            the Offer Period.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If you are not a wallet partner registered customer, you will be
            required to register on the wallet partner website or Mobile App to
            receive your wallet cashback.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The customer will be responsible for entering details such as UPI
            ID, Online Wallet Mob Nos, etc. Premier Sales Promotions Pvt Ltd.
            will not be responsible for any incorrect entries or details on the
            promotion website. No change in details will be entertained once you
            have submitted the booking.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Premier Sales Promotions Private Limited or Mayora India Pvt. Ltd.
            will not be responsible for cashback failures due to any failure at
            the payment gateway or payment processing partner end.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Payment Processing / Wallet Partner holds the right to refuse
            transactions deemed fraudulent as per their policies.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In no event shall Premiere Sales Promotions Pvt Ltd or Mayora India
            Private Limited be held liable for any issues relating to the
            redemption of the voucher including redemption requests not being
            honored by the wallet partner/payment processing partner.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            In no event shall the maximum liability of Premier Sales Promotions
            Pvt Ltd or Mayora India Pvt. Ltd. To the customer exceed the value
            of the voucher.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Any disputes or differences in connection with the offer shall be
            governed by the laws of India and shall be subject to the exclusive
            jurisdiction of the Courts at Hyderabad.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The payment processing/wallet partner reserves the right to restrict
            cashback for any account with suspicious behavior or invalid
            details/credentials. The wallet partner has the right to ask for
            documents to prove the customer/user's identity.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This offer is subject to promotional availability and government
            regulations in every State of India.
        </p>
    </li>
</ol>
<p dir="ltr">
    General Terms &amp; Conditions
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            The unique code entitles you to an assured cashback of Rs. 20 or Rs.
            35 or Rs. 50 or Rs. 100. The denomination of the cashback won will
            be mentioned on the promotional website post successful registration
            by the participant.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This offer is not available to employees of Mayora India Private
            Limited and Premier Sales Promotions Pvt Ltd, its clients, trade
            partners, dealers, distributors or agencies and their immediate
            families.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The usage of the unique code is incumbent upon the terms &amp;
            conditions to be followed by the promotional pack/unique code
            holder/purchaser. Such conditions need to be followed by the
            promotional pack/unique code holder/purchaser.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The "How to Use/Redeem" instructions form a part of these Terms
            &amp; Conditions.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The Cashback Promotion shall run on the selected SKUs only.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Any costs associated with entering the promotion, including
            accessing the promotional website, are the responsibility of each
            entrant. Also, regular call and message rates will apply as per the
            consumer telecom operator in their respective circles.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Only one unique code per registration will be entertained. Multiple
            unique codes cannot be registered together.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            A maximum of one unique code can be used per mobile number per day
            up to a maximum of 5 (five) unique codes during the promotion period
            for Cheese and Double Chocolatey separately.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Premier Sales Promotions Pvt Ltd is acting as an executing agency
            for this promotional offer and holds no legal and financial
            liability on unclaimed offers, incentives, unique codes, etc beyond
            the applicable period of the promotional offer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            This offer is subject to promotional availability and government
            regulations.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Under this promotion, the unique codes are being offered directly by
            Premier Sales Promotions Pvt Ltd. Mayora India Private Limited is
            not responsible either directly or indirectly with respect to any
            matter pertaining to the unique code and the cashback offered
            against the unique codes.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Premier Sales Promotions Pvt Ltd. and Mayora India Private Limited
            shall not be liable in any manner for any cost or expense incurred
            for the purpose of redemption of the unique code by the Customer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            If this promotion is interfered with in any way or is not capable of
            being conducted as reasonably anticipated due to any reason beyond
            the reasonable control of either PSPL or MIPL, including but not
            limited to technical difficulties, unauthorized intervention or
            fraud, the MIPL reserves the right, in its sole discretion, to the
            fullest extent permitted by law (a) to invalidate any customer; or
            (b) subject to any written directions from a regulatory authority,
            to modify, suspend, terminate or cancel the promotion, as
            appropriate.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Premier Sales Promotion Pvt. Ltd. and Mayora India Private Limited
            is not responsible for any problems or technical malfunction of any
            telephone or network or lines, servers or providers, computer
            equipment, software, technical problems or traffic congestion on a
            mobile network, or any combination thereof, or any other technical
            failures including any damage to entrant's or any other person's
            mobile handset or computer related to, or resulting from,
            participation in this promotion or the downloading of any materials
            related to this promotion.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Caution: Any attempt to deliberately damage the promotional website
            (
            <a href="http://www.malkistcashback.com">
                www.malkistcashback.com {" "}
            </a>
            and www.malkistpromo.com) or the information on the website, to
            cause malicious damage or interference with the normal functioning
            of the website, or to otherwise undermine the legitimate operation
            of this promotion may be a violation of criminal and civil laws and
            should such an attempt be made, whether successful or not, the
            Promoter ("MIPL") reserves the right to seek damages to the fullest
            extent permitted by law. If the Promoter suffers loss or incurs any
            costs in connection with any breach of these Terms or any other
            legal obligation by an entrant, the entrant agrees to indemnify the
            promoter for those losses, damages, and costs.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            The unique code has no monetary value, is non-transferable and
            cannot be used in conjunction with any other promotional offer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Premier Sales Promotions Pvt Ltd. or Mayora India Private Limited
            will have no legal and financial liability on unclaimed offers,
            incentives, unique codes, etc beyond the applicable period of the
            promotional offer.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Premier Sales Promotions Pvt Ltd., its clients, agents, and
            distributors accept no responsibility, financial or otherwise for
            the misuse of Premier Sales Promotions Pvt Ltd. unique codes and are
            not liable for any personal loss or injury and cannot be held
            responsible for any disagreement concerning quality/ availability of
            featured offers.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Last Date to Register: 28/12/2024
        </p>
    </li>
</ol>

    </Popup>
  );
};

export default TermsPopup;
