import "./Participated.scss";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../../api";
import { useDispatch, useSelector } from "react-redux";
// import {
//   isFirstGame,
//   setIndividualScore,
// } from "../../store/actions/authAction";
import { useAuthentication } from "../../hooks/useAuthentication";
import { RootState } from "../../store/store";
import { GA_EVENTS, gtagTrackEvent } from "../../lib/utils";
import congrats from "../../assets/images/congrats.png";
import chocolate from "../../assets/images/bottom.png"


export const Participated = () => {
  const { isLoggedIn } = useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | any>(null);
  const [optionClicked, setOptionClicked] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>("");
  //   const GameKey = useSelector((state: RootState) => state.authReducer.gameKey);
  const Url: string = "https://www.piyojeetojitao.com/";
  // const [copied, setCopied] = useState<boolean>(false);

  // useEffect(() => {
  //   API.getReferral().then((response) => {
  //     if (response.referral) {
  //       setReferralCode(response.referral);
  //       gtagTrackEvent(GA_EVENTS.Thank_You);
  //     } else {
  //       // Handle unexpected response structure or set a default message
  //       setReferralCode("Referral Code");
  //     }
  //   });
  // }, []);
  //
  const handleOptionClick = async (option: number) => {
    // if (!optionClicked) {
    // setOptionClicked(true);
    setSelectedOption(option);
  };
  const handleSubmit = () => {
    {
      /*navigate(ROUTES.HOME);*/
    }
  };



  // const handleCopy = () => {
  //   setCopied(true);
  //   gtagTrackEvent(GA_EVENTS.Copy_Referral_Code); 
  // };

  // const handleShare = () => {
  //   gtagTrackEvent(GA_EVENTS.Share_Referral_Code); 
  // };

  return (
    <MainLayout className="register-page">
      <div className="participated-form">
        <div className="Participated-container">
          {/*<img src={Text} alt="logo-top" />*/}
          <p className="Participated-title">Oh Snap!</p>
          <p className="Participated-text">
          Looks like you’ve exhausted<br/> your limit on<br/> Malkist Double Chocolatey Crackers.
          </p>
          <img
          src={chocolate}
          alt="Participated"
          className="Participated-image"
        />

          <p className="Participated-text-sub">
          Try Malkist Cheese Crackers to unlock more Cashback.
          </p>
          
        </div>
      </div>
      
    </MainLayout>
  );
};
